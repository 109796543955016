<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import LandlordNr4SlipQueryDialog from './components/landlord_nr4_slip_query_dialog.vue'
import LandlordBankInfoDilaog from './components/update_landlord_bank_info_dialog.vue'
import LandlordInfoDilaog from './components/landlord_info_dialog.vue'
import { getPMApi } from '@/api/pm'
import { getOwnerApi } from "@/api/pm/owner";



/**
 * User list component
 */
export default {
  page: {
    title: "Owner List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      landlordList: [],
      title: "Owner List",
      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "Owner List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 30,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      search_str: '',
      fields: [
        {
          key: "LandLordID",
          sortable: true,
        },
        {
          key: "Name",
          sortable: true,
        },

        {
          key: "Phone",
          sortable: true,
        },
        {
          key: "Email1",
          sortable: true,
        },
        { key: 'PaymentType' },

        {
          key: "NonResident",
          sortable: true,
        },
        {
          key: "Action",
          sortable: true,
        },
      ],

      current_landlord: {}
    };
  },
  components: {
    Layout,
    PageHeader,
    LandlordBankInfoDilaog,
    LandlordInfoDilaog,
    LandlordNr4SlipQueryDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
    this.queryList()
  },
  mounted() {

  },
  methods: {

    queryList() {
      getOwnerApi().owner_list({ owner_code: this.search_str, page: { page: this.currentPage, pageSize: this.perPage } }).then((res) => {
        this.landlordList = []
        res.data.map((o) => {

          this.landlordList.push({
            id: o.id,
            code: o.code,
            landlord_id: o.code,
            name: o.is_corporatuin == 1 ? `${o.company_name}` : `${o.landlord_name}`,
            apt: o.apt,
            address: o.address,
            city_name: o.city_name,
            province_name: o.province_name,
            country      : o.country,
            phone: o.phone1,
            email: o.email,
            status: o.status,
            accounting_gst: o.accounting_gst,
            is_resident: o.is_resident,
            bank_account: o.bank_account,
            institution: o.institution,
            transfer: o.transfer,
            account_number: o.account_number,
            rent_type: o.rent_type,
            email2: o.email2,
            is_send_mail: o.is_send_mail,

            is_eft_email : o.is_eft_email,
          })


        })

        this.totalRows = res.page.total;

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },


    search() {
      this.currentPage = 1
      this.queryList()
    },


    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    delete_landlord(l) {
      getPMApi().delete_landlord({ landlord_id: l.id }).then((res) => {
        if (res.errCode == 0) {
          this.landlordList = this.landlordList.filter((e) => e.id != l.id)
          this.$alertify.message("Remove Landlord Success");
        } else {
          this.$alertify.error("Remove Landlord Failed code:" + res.errCode);
        }
      })
    },



    onLandlordBankInfoUpdate() {
      this.$bvModal.hide('landlord_bank_update_dialog')
    },

    showLandlordBankInfoDialog(landlord) {
      this.current_landlord = landlord
      this.$bvModal.show('landlord_bank_update_dialog')
    },

    showNr4Slip(owner) {
      this.current_landlord = owner
      this.$bvModal.show('landlord_nr4_slip_query_dialog')

    },

    showLandlordInfo(landlord) {
      this.current_landlord = landlord
      this.$bvModal.show('landlord_info_dialog')
    },

    export_owner_list() {
      getPMApi().export_owner_list({}).then(res => {
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        } else {
          this.$alertify.error("Export Failed" + res.errCode);
        }
      })
    }

  },

};
</script>

<template>
  <Layout>


    <b-modal centerd id="landlord_bank_update_dialog" title="Landlord Bank Info Update" size="lg" hide-footer>
      <LandlordBankInfoDilaog :landlord="current_landlord" @confirm="onLandlordBankInfoUpdate"
        @cancel="$bvModal.hide('landlord_bank_update_dialog')" />
    </b-modal>

    <b-modal centerd id="landlord_info_dialog" title="Landlord Info Update" size="lg" hide-footer>
      <LandlordInfoDilaog :landlord="current_landlord" @cancel="$bvModal.hide('landlord_info_dialog')" />
    </b-modal>

    <b-modal centerd id="landlord_nr4_slip_query_dialog" title="Owner Nr4 Query" size="lg" hide-footer>
      <LandlordNr4SlipQueryDialog :landlord="current_landlord"
        @cancel="$bvModal.hide('landlord_nr4_slip_query_dialog')" />
    </b-modal>


    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="row">


              <div class="col-md-4">
                <input class="form-control" type="text" placeholder="Search Owner by Code" v-model="search_str" />
              </div>
              <div class="col-md-8">
                <div class="d-flex flex-wrap align-items-start justify-content-md-end   gap-4">
                  <b-button variant="primary" @click="search">Search</b-button>
                  <b-button variant="primary" @click="$router.push({ name: 'pm-add-landlord' })">Add Owner</b-button>
                  <b-button variant="primary" @click="export_owner_list">Export</b-button>

                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <div class="card-body">


            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="landlordList" :fields="fields" responsive="sm" :per-page="perPage"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                  @filtered="onFiltered" striped hover class="table-check">

                  <template #cell(LandLordID)="data">

                    <b-link @click="showLandlordInfo(data.item)">{{ data.item.landlord_id }}</b-link>
                  </template>
                  <template #cell(Name)="data">
                    {{ data.item.name }}
                  </template>
                  <template #cell(Address)="data">
                    {{ data.item.address }}
                  </template>
                  <template #cell(Phone)="data">
                    {{ data.item.phone }}
                  </template>
                  <template #cell(Email1)="data">
                    {{ data.item.email }}
                  </template>
                  <template #cell(Email2)="data">
                    {{ data.item.email2 }}
                  </template>
                  <template #cell(NonResident)="data">
                    {{ data.item.is_resident == 0 ? 'Yes' : 'No' }}
                  </template>
                  <template #cell(PaymentType)="data">
                    {{ data.item.rent_type }}
                  </template>

                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>

                      <b-dropdown-item @click="showLandlordBankInfoDialog(data.item)">Edit</b-dropdown-item>
                      <b-dropdown-item @click="showNr4Slip(data.item)"
                        :disabled="data.item.is_resident == 1">Nr4Slip</b-dropdown-item>
                    </b-dropdown>
                  </template>


                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
